import * as __WEBPACK_EXTERNAL_MODULE_react_compiler_runtime__ from "react/compiler-runtime";
import * as __WEBPACK_EXTERNAL_MODULE_date_fns__ from "date-fns";
import * as __WEBPACK_EXTERNAL_MODULE_react__ from "react";
import * as __WEBPACK_EXTERNAL_MODULE__toss_react__ from "@toss/react";
import * as __WEBPACK_EXTERNAL_MODULE__toss_storage__ from "@toss/storage";
import * as __WEBPACK_EXTERNAL_MODULE_react_relay__ from "react-relay";
import * as __WEBPACK_EXTERNAL_MODULE__daangn_car_utils_misc__ from "@daangn/car-utils/misc";
import * as __WEBPACK_EXTERNAL_MODULE_es_toolkit_compat__ from "es-toolkit/compat";
import * as __WEBPACK_EXTERNAL_MODULE_immer__ from "immer";
const useLatest = (value)=>{
    const ref = (0, __WEBPACK_EXTERNAL_MODULE_react__.useRef)(value);
    ref.current = value;
    return ref;
};
const INTERVAL = 1000;
const useCountdown = (t0)=>{
    const $ = (0, __WEBPACK_EXTERNAL_MODULE_react_compiler_runtime__.c)(17);
    const { initialEndsAt: t1, onTimeEnd } = t0;
    const initialEndsAt = void 0 === t1 ? 0 : t1;
    const intervalRef = (0, __WEBPACK_EXTERNAL_MODULE_react__.useRef)();
    const onTimeEndRef = useLatest(onTimeEnd);
    const [endsAt, setEndsAt] = (0, __WEBPACK_EXTERNAL_MODULE_react__.useState)(initialEndsAt);
    let t2;
    if ($[0] !== endsAt) {
        t2 = (0, __WEBPACK_EXTERNAL_MODULE_date_fns__.intervalToDuration)({
            start: new Date(),
            end: new Date(endsAt)
        });
        $[0] = endsAt;
        $[1] = t2;
    } else t2 = $[1];
    const [duration, setDuration] = (0, __WEBPACK_EXTERNAL_MODULE_react__.useState)(t2);
    let t3;
    if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
        t3 = ()=>{
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = void 0;
            }
        };
        $[2] = t3;
    } else t3 = $[2];
    const clearIntervalRef = t3;
    let t4;
    if ($[3] !== clearIntervalRef) {
        t4 = ()=>{
            setEndsAt(0);
            clearIntervalRef();
        };
        $[3] = clearIntervalRef;
        $[4] = t4;
    } else t4 = $[4];
    const clearCountdown = t4;
    let t5;
    let t6;
    if ($[5] !== endsAt || $[6] !== clearIntervalRef) {
        t5 = ()=>{
            if (endsAt <= Date.now()) {
                setDuration({
                    seconds: 0
                });
                return;
            }
            clearIntervalRef();
            intervalRef.current = setInterval(()=>{
                setDuration((0, __WEBPACK_EXTERNAL_MODULE_date_fns__.intervalToDuration)({
                    start: new Date(),
                    end: new Date(endsAt)
                }));
            }, INTERVAL);
            return ()=>clearIntervalRef();
        };
        t6 = [
            clearIntervalRef,
            endsAt
        ];
        $[5] = endsAt;
        $[6] = clearIntervalRef;
        $[7] = t5;
        $[8] = t6;
    } else {
        t5 = $[7];
        t6 = $[8];
    }
    (0, __WEBPACK_EXTERNAL_MODULE_react__.useEffect)(t5, t6);
    let t7;
    let t8;
    if ($[9] !== onTimeEndRef || $[10] !== duration || $[11] !== clearIntervalRef) {
        t7 = ()=>{
            if (intervalRef.current && onTimeEndRef.current) {
                const { days, hours, minutes, months, seconds, weeks, years } = duration;
                const isEmptyDuration = !years && !months && !weeks && !days && !hours && !minutes && !seconds;
                if (isEmptyDuration) {
                    onTimeEndRef.current();
                    clearIntervalRef();
                }
            }
        };
        t8 = [
            clearIntervalRef,
            duration,
            onTimeEndRef
        ];
        $[9] = onTimeEndRef;
        $[10] = duration;
        $[11] = clearIntervalRef;
        $[12] = t7;
        $[13] = t8;
    } else {
        t7 = $[12];
        t8 = $[13];
    }
    (0, __WEBPACK_EXTERNAL_MODULE_react__.useEffect)(t7, t8);
    let t9;
    if ($[14] !== duration || $[15] !== clearCountdown) {
        t9 = {
            duration,
            setEndsAt,
            clearCountdown
        };
        $[14] = duration;
        $[15] = clearCountdown;
        $[16] = t9;
    } else t9 = $[16];
    return t9;
};
const useLocalStorageState = (key, defaultValue)=>{
    const $ = (0, __WEBPACK_EXTERNAL_MODULE_react_compiler_runtime__.c)(2);
    let t0;
    if ($[0] !== defaultValue) {
        t0 = {
            defaultValue,
            storage: __WEBPACK_EXTERNAL_MODULE__toss_storage__.safeLocalStorage
        };
        $[0] = defaultValue;
        $[1] = t0;
    } else t0 = $[1];
    return (0, __WEBPACK_EXTERNAL_MODULE__toss_react__.useStorageState)(key, t0);
};
const useMutationAsync = (mutation)=>{
    const $ = (0, __WEBPACK_EXTERNAL_MODULE_react_compiler_runtime__.c)(5);
    const [mutate, loading] = (0, __WEBPACK_EXTERNAL_MODULE_react_relay__.useMutation)(mutation);
    let t0;
    if ($[0] !== mutate) {
        t0 = (params)=>new Promise((resolve, reject)=>{
                mutate({
                    ...params,
                    onCompleted (response, errors) {
                        if (response) resolve(response);
                        reject(errors);
                    },
                    onError (error) {
                        reject(error);
                    }
                });
            });
        $[0] = mutate;
        $[1] = t0;
    } else t0 = $[1];
    const mutateAsync = t0;
    let t1;
    if ($[2] !== mutateAsync || $[3] !== loading) {
        t1 = [
            mutateAsync,
            loading
        ];
        $[2] = mutateAsync;
        $[3] = loading;
        $[4] = t1;
    } else t1 = $[4];
    return t1;
};
const useSessionStorageState = (key, defaultValue)=>{
    const $ = (0, __WEBPACK_EXTERNAL_MODULE_react_compiler_runtime__.c)(2);
    let t0;
    if ($[0] !== defaultValue) {
        t0 = {
            defaultValue,
            storage: __WEBPACK_EXTERNAL_MODULE__toss_storage__.safeSessionStorage
        };
        $[0] = defaultValue;
        $[1] = t0;
    } else t0 = $[1];
    return (0, __WEBPACK_EXTERNAL_MODULE__toss_react__.useStorageState)(key, t0);
};
const useSetStateSelector = (setValue, path)=>{
    const $ = (0, __WEBPACK_EXTERNAL_MODULE_react_compiler_runtime__.c)(3);
    let t0;
    if ($[0] !== setValue || $[1] !== path) {
        t0 = (update)=>{
            setValue((prevValue)=>(0, __WEBPACK_EXTERNAL_MODULE_immer__.produce)(prevValue, (draft)=>{
                    (0, __WEBPACK_EXTERNAL_MODULE_es_toolkit_compat__.set)(draft, path, "function" == typeof update ? update((0, __WEBPACK_EXTERNAL_MODULE__daangn_car_utils_misc__.getIn)(draft, path)) : update);
                }));
        };
        $[0] = setValue;
        $[1] = path;
        $[2] = t0;
    } else t0 = $[2];
    const result = t0;
    return result;
};
const useRefetchableLazyLoadQuery = (...t0)=>{
    const $ = (0, __WEBPACK_EXTERNAL_MODULE_react_compiler_runtime__.c)(5);
    const params = t0;
    const [tag, variables, options] = params;
    let t1;
    if ($[0] !== options) {
        t1 = {
            ...options,
            fetchKey: 0
        };
        $[0] = options;
        $[1] = t1;
    } else t1 = $[1];
    const [queryOptions, setQueryOptions] = (0, __WEBPACK_EXTERNAL_MODULE_react__.useState)(t1);
    const data = (0, __WEBPACK_EXTERNAL_MODULE_react_relay__.useLazyLoadQuery)(tag, variables, queryOptions);
    let t2;
    if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
        t2 = (t3)=>{
            const nextFetchPolicy = void 0 === t3 ? "network-only" : t3;
            return setQueryOptions((prev)=>({
                    ...prev,
                    fetchKey: (prev?.fetchKey || 0) + 1,
                    fetchPolicy: nextFetchPolicy
                }));
        };
        $[2] = t2;
    } else t2 = $[2];
    const refetch = t2;
    let t3;
    if ($[3] !== data) {
        t3 = [
            data,
            refetch
        ];
        $[3] = data;
        $[4] = t3;
    } else t3 = $[4];
    return t3;
};
export { useCountdown, useLatest, useLocalStorageState, useMutationAsync, useRefetchableLazyLoadQuery, useSessionStorageState, useSetStateSelector };
